export const isMongoId = (str) => {
    var bool=false; 
    if(str.length==24) bool=/[a-f]+/.test(str);
    return bool;
}

export const removeNumberCommaAndConvertToNumber = (s) => {

    if(s.replace) {
        return parseFloat(s.replace(/,/g, ''));        
    }

    return parseFloat(""+s)
}

export const toFloat = (s) => {
    return parseFloat(""+s)
}