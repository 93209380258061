import { Navigate, useRoutes } from 'react-router-dom'
// layouts
import DashboardLayout from './layouts/dashboard'
import LogoOnlyLayout from './layouts/LogoOnlyLayout'
import NoLogoLayout from './layouts/NoLogoLayout'
//
import Login from './pages/Login'
import DashboardApp from './pages/DashboardApp'
import NotFound from './pages/Page404'
import Auth from './pages/Auth'

import IntegratorList from './pages/integrators/list'
import IntegratorEdit from './pages/integrators/edit'
import IntegratorSave from './pages/integrators/save'

import BeneficiaryList from './pages/beneficiaries/list'
import BeneficiaryEdit from './pages/beneficiaries/edit'
import BeneficiarySave from './pages/beneficiaries/save'
import BeneficiaryView from './pages/beneficiaries/view'
import BeneficiaryTransfers from './pages/beneficiaries/transfers'
import BeneficiaryPayments from './pages/beneficiaries/payments'
import BeneficiaryDocuments from './pages/beneficiaries/documents'
import BeneficiaryBalance from './pages/beneficiaries/balance'
import BeneficiaryStatements from './pages/beneficiaries/statements'
import BeneficiaryAmlCheck from './pages/beneficiaries/aml-check'
import BeneficiaryKyx from './pages/beneficiaries/kyx'


import PendingWireList from './pages/pending-wire/list'

import CurrencyList from './pages/currencies/list'
import CurrencySave from './pages/currencies/save'
import CurrencyEdit from './pages/currencies/edit'

import SwiftTransfersList from './pages/pending-swift-transfers/list'
import SwiftTransfersEdit from './pages/pending-swift-transfers/edit'

import ExchangedFundsAvailableList from './pages/exchanged-funds-available/list'
import ExchangedFundsAvailableEdit from './pages/exchanged-funds-available/edit'

import LedgerTemplateEdit from './pages/ledger-templates/ledger_template'

import CountryList from './pages/countries/list'
import CountrySave from './pages/countries/save'
import CountryEdit from './pages/countries/edit'

import AdminList from './pages/admins/list'
import AdminSave from './pages/admins/save'
import AdminEdit from './pages/admins/edit'

import ReportGeneration from './pages/reports/ReportGeneration'

import PaymentContactList from './pages/payment-contact/list'
import PaymentContactSave from './pages/payment-contact/save'
import PaymentContactEdit from './pages/payment-contact/edit'
import AccountTree from './pages/account-tree/tree'

import AmlLockList from './pages/aml-lock/list'
import AmlLockSave from './pages/aml-lock/save'
import AmlLockEdit from './pages/aml-lock/edit'

import TransferList from './pages/transfers/list'
import TransferView from './pages/transfers/view'

import TeamList from './pages/team/list'
import TeamSave from './pages/team/save'

import PaymentList from './pages/payments/list'
import PaymentView from './pages/payments/view'

import FakeTransferService from './pages/fake-transfers/save'
import FakePayamentService from './pages/fake-payments/save'

import ProfilePage from './pages/profiles/profile'

import BalancePage from './pages/balance/balance'
import WalletPage from './pages/wallets/wallet'

import StatementView from './pages/statements/view'
import TView from './pages/t-account/TView'
import TransactionAccounts from './pages/transactions/Accounts'
import AccountsList from './pages/accounts/Accounts'
import TransactionList from './pages/transactions/Transactions'


import Main from './pages/Main'

import Signup from './pages/Signup'

import IntegratorRegistered from './pages/IntegratorRegistered'

import {
  getAvailableRoutesForCurrentUser,
  checkRoute,
} from './services/authenticationService'

import StartBatchOperationIn from './pages/batch_operation_in/start-batch-operation'
import ListBatchOperationsIn from './pages/batch_operation_in/list-batch-operations'

import CompleteBatchOperationOut from './pages/batch_operation_out/complete-batch-operation'
import ListBatchOperationsOut from './pages/batch_operation_out/list-batch-operations'

import DepositRefundingList from './pages/deposit_refunding/list'
import DepositRefundingEdit from './pages/deposit_refunding/edit'

// ----------------------------------------------------------------------

export default function Router() {
  const availableRoutes = getAvailableRoutesForCurrentUser()
  const isProduction = process.env.REACT_APP_IS_PRODUCTION === 'MAIN'

  const dashboardRoutes = [
    { element: <Navigate to='/dashboard/app' replace /> },

    { path: 'app', element: <DashboardApp /> },

    {
      path: 'integrators/list',
      resource: 'integrators',
      action: 'list',
      element: <IntegratorList />,
    },
    {
      path: 'integrators/save',
      resource: 'integrators',
      action: 'add',
      element: <IntegratorSave />,
    },
    {
      path: 'integrators/:id/edit',
      resource: 'integrators',
      action: 'edit',
      element: <IntegratorEdit />,
    },
    {
      path: 'beneficiaries/list',
      resource: 'beneficiaries',
      action: 'list',
      element: <BeneficiaryList />,
    },
    {
      path: 'beneficiaries/save',
      resource: 'beneficiaries',
      action: 'add',
      element: <BeneficiarySave />,
    },
    {
      path: 'beneficiaries/:id/view',
      resource: 'beneficiaries',
      action: 'edit',
      element: <BeneficiaryView />,
    },
    {
      path: 'beneficiaries/:id/edit',
      resource: 'beneficiaries',
      action: 'edit',
      element: <BeneficiaryEdit />,
    },
    {
      path: 'beneficiaries/:id/transfers',
      resource: 'beneficiaries',
      action: 'list',
      element: <BeneficiaryTransfers />,
    },
    {
      path: 'beneficiaries/:id/payments',
      resource: 'beneficiaries',
      action: 'list',
      element: <BeneficiaryPayments />,
    },
    {
      path: 'beneficiaries/:id/documents',
      resource: 'beneficiaries',
      action: 'list',
      element: <BeneficiaryDocuments />,
    },
    {
      path: 'beneficiaries/:id/balance',
      resource: 'beneficiaries',
      action: 'list',
      element: <BeneficiaryBalance />,
    },
    {
      path: 'beneficiaries/:id/statements',
      resource: 'beneficiaries',
      action: 'list',
      element: <BeneficiaryStatements />,
    },
    {
      path: 'beneficiaries/:id/statements',
      resource: 'beneficiaries',
      action: 'list',
      element: <BeneficiaryBalance />,
    },
    {
      path: 'beneficiaries/:id/amlcheck',
      resource: 'beneficiaries',
      action: 'list',
      element: <BeneficiaryAmlCheck />,
    },
    {
      path: 'beneficiaries/:id/kyx',
      resource: 'beneficiaries',
      action: 'list',
      element: <BeneficiaryKyx />,
    },
    {
      path: 'beneficiaries/:id/statements/:statementId/view',
      resource: 'statements',
      action: 'list',
      element: <StatementView />,
    },
    {
      path: 'transfers/list',
      resource: 'transfers',
      action: 'list',
      element: <TransferList />,
    },
    {
      path: 'transfers/:id/view',
      resource: 'transfers',
      action: 'edit',
      element: <TransferView />,
    },
    {
      path: 'team/list',
      resource: 'team',
      action: 'list',
      element: <TeamList />,
    },
    {
      path: 'team/save',
      resource: 'team',
      action: 'add',
      element: <TeamSave />,
    },
    {
      path: 'payments/list',
      resource: 'payments',
      action: 'list',
      element: <PaymentList />,
    },
    {
      path: 'payments/:id/view',
      resource: 'payments',
      action: 'edit',
      element: <PaymentView />,
    },

    {
      path: 'currencies/list',
      resource: 'currencies',
      action: 'list',
      element: <CurrencyList />,
    },
    {
      path: 'currencies/save',
      resource: 'currencies',
      action: 'add',
      element: <CurrencySave />,
    },
    {
      path: 'currencies/:id/edit',
      resource: 'currencies',
      action: 'edit',
      element: <CurrencyEdit />,
    },

    {
      path: 'pending-swift-transfers/list',
      resource: 'pending-swift-transfers',
      action: 'list',
      element: <SwiftTransfersList />,
    },
    {
      path: 'pending-swift-transfers/:id/edit',
      resource: 'pending-swift-transfers',
      action: 'edit',
      element: <SwiftTransfersEdit />,
    },

    {
      path: 'exchanged-funds-available/list',
      resource: 'exchanged-funds-available',
      action: 'list',
      element: <ExchangedFundsAvailableList />,
    },
    {
      path: 'exchanged-funds-available/:id/edit',
      resource: 'exchanged-funds-available',
      action: 'edit',
      element: <ExchangedFundsAvailableEdit />,
    },

    {
      path: 'payment-contacts/list',
      resource: 'payment-contacts',
      action: 'list',
      element: <PaymentContactList />,
    },
    {
      path: 'payment-contacts/save',
      resource: 'payment-contacts',
      action: 'add',
      element: <PaymentContactSave />,
    },
    {
      path: 'payment-contacts/:id/edit',
      resource: 'payment-contacts',
      action: 'edit',
      element: <PaymentContactEdit />,
    },
    {
      path: 'accounts/tree',
      resource: 'accounts-tree',
      action: 'edit',
      element: <AccountTree />,
    },
    {
      path: 'transactions/:id/accounts/view',
      resource: 'transaction-accounts-view',
      action: 'edit',
      element: <TransactionAccounts />,
    },
    {
      path: 'accounts/transactions/view',
      resource: 'accounts-transactions-view',
      action: 'edit',
      element: <AccountsList />,
    },
    {
      path: 'accounts/:id/transactions/list',
      resource: 'accounts-transactions-list',
      action: 'list',
      element: <TransactionList />,
    },
    {
      path: 'accounts/t-view',
      resource: 'accounts-t-view',
      action: 'edit',
      element: <TView />,
    },
    {
      path: 'countries/list',
      resource: 'countries',
      action: 'list',
      element: <CountryList />,
    },
    {
      path: 'countries/save',
      resource: 'countries',
      action: 'add',
      element: <CountrySave />,
    },
    {
      path: 'countries/:id/edit',
      resource: 'countries',
      action: 'edit',
      element: <CountryEdit />,
    },

    {
      path: 'admins/list',
      resource: 'admins',
      action: 'list',
      element: <AdminList />,
    },
    {
      path: 'admins/save',
      resource: 'admins',
      action: 'add',
      element: <AdminSave />,
    },
    {
      path: 'admins/:id/edit',
      resource: 'admins',
      action: 'edit',
      element: <AdminEdit />,
    },
    {
      path: 'reports/save',
      resource: 'reports',
      action: 'add',
      element: <ReportGeneration />,
    },

    {
      path: 'ledger_templates/edit',
      resource: 'ledger_templates',
      action: 'edit',
      element: <LedgerTemplateEdit />,
    },

    {
      path: 'profiles',
      resource: 'profiles',
      action: 'edit',
      element: <ProfilePage />,
    },
    {
      path: 'balance',
      resource: 'balance',
      action: 'list',
      element: <BalancePage />,
    },
    {
      path: 'wallets',
      resource: 'wallets',
      action: 'list',
      element: <WalletPage />,
    },
    {
      path: 'pendingwire/list',
      resource: 'pendingwire',
      action: 'list',
      element: <PendingWireList />,
    },
    {
      path: 'amllock/list',
      resource: 'amllock',
      action: 'list',
      element: <AmlLockList />,
    },
    {
      path: 'amllock/save',
      resource: 'amllock',
      action: 'add',
      element: <AmlLockSave />,
    },
    {
      path: 'amllock/:id/edit',
      resource: 'amllock',
      action: 'edit',
      element: <AmlLockEdit />,
    },
    {
      path: 'batch-operation/in/list',
      resource: 'batchoperation',
      action: 'list',
      element: <ListBatchOperationsIn />,
    },
    {
      path: 'batch-operation/in/:id/start',
      resource: 'batchoperation',
      action: 'edit',
      element: <StartBatchOperationIn />,
    },
    {
      path: 'batch-operation/out/list',
      resource: 'batchoperation',
      action: 'list',
      element: <ListBatchOperationsOut />,
    },
    {
      path: 'batch-operation/out/:id/complete',
      resource: 'batchoperation',
      action: 'edit',
      element: <CompleteBatchOperationOut />,
    },

    {
      path: 'deposit-refunding/list',
      resource: 'deposit-refunding',
      action: 'list',
      element: <DepositRefundingList />,
    },
    {
      path: 'deposit-refunding/:id/edit',
      resource: 'deposit-refunding',
      action: 'edit',
      element: <DepositRefundingEdit />,
    },
  ].filter(item => checkRoute(availableRoutes, item))

  if (!isProduction) {
    dashboardRoutes.push({
      path: 'transfers/fake',
      resource: 'transfers',
      action: 'add',
      element: <FakeTransferService />,
    })
    dashboardRoutes.push({
      path: 'payments/fake',
      resource: 'payments',
      action: 'add',
      element: <FakePayamentService />,
    })
  }

  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: dashboardRoutes,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to='/main' /> },
        { path: '/main', element: <Main /> },
        { path: '*', element: <Navigate to='/404' /> },
        { path: 'callback', element: <Auth /> },
      ],
    },
    {
      path: '/',
      element: <NoLogoLayout />,
      children: [
        { path: 'signup', element: <Signup /> },
        { path: '/created', element: <IntegratorRegistered /> },
      ],
    },
    { path: '*', element: <Navigate to='/404' replace /> },
  ])
}
