import { useEffect, useState, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  mapFromIndividualRequest,
  mapFromBusinessRequest,
} from '../../utils/beneficiaries'
import { SECTION_BASE_PATHS } from '../../utils/general'

// Services
import { getBeneficiaryById } from '../../services/beneficiaryService'
import { errorAlert } from '../../services/alertService'
import { getRoles } from '../../services/authenticationService'

// Components
import Page from '../../components/Page'
import PageHeader from '../../components/PageHeader'
import Loading from '../../components/Loading'
import TransfersList from '../../pages/transfers/list'

// COnstants
const BENEFICIARIES_BASE_PATH = SECTION_BASE_PATHS.beneficiaries

BeneficiaryDetailPage.propTypes = {
  data: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }),
}

export default function BeneficiaryDetailPage(props) {
  const navigate = useNavigate()
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)

  // Button click handlers
  const goToListPage = () => navigate(`${BENEFICIARIES_BASE_PATH}/list`)
  const goToViewPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/view`)
  const goToTransfersPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/transfers`)
  const goToPaymentsPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/payments`)
  const goToDocumentsPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/documents`)
  const goToBalance = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/balance`)
  const goToStatements = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/statements`)
  const goToAmlCheck = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/amlcheck`)
  const goToKyx = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/kyx`)      
  const fetchBeneficiaryData = useCallback(() => {
    setLoading(true)
    getBeneficiaryById(id)
      .then(beneficiary => {
        const data =
          beneficiary.type === 'INDIVIDUAL'
            ? mapFromIndividualRequest(beneficiary)
            : mapFromBusinessRequest(beneficiary)
        setData(data)
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        errorAlert('Error has occurred while fetching integrator info')
        setLoading(false)
      })
  }, [setData, setLoading, id])

  useEffect(() => {
    fetchBeneficiaryData()
  }, [fetchBeneficiaryData])

  if (!data) {
    return null
  }

  const tabsData = {
    tabs: [
      { label: 'Basic Info', value: 'view', onClick: goToViewPage },
      {
        label: 'Transfers',
        value: 'transfers',
        onClick: goToTransfersPage,
      },
      { label: 'Payments', value: 'payments', onClick: goToPaymentsPage },
      {
        label: 'Documents',
        value: 'documents',
        onClick: goToDocumentsPage,
      },
      {
        label: 'Balance',
        value: 'balance',
        onClick: goToBalance,
      },
      {
        label: 'KYX',
        value: 'kyx',
        onClick: goToKyx,
      }
    ],
    selectedTab: 'transfers',
  }

  const isAdmin = getRoles().indexOf('ROLE_ADMIN') > -1

  if(isAdmin) {
    tabsData.tabs.push({
      label: 'AML Check',
      value: 'amlcheck',
      onClick: goToAmlCheck,
    })
  }

  return (
    <Page className='main_content' title={`Beneficiary | Transfers`}>
      <Loading loading={loading}>
        <PageHeader
          backButtonAction={goToListPage}
          heading={`Beneficiary | ${data.firstName || data.name} ${
            data.lastName || ''
          } - Basic Info`}
          tabsData={tabsData}
        />

        <TransfersList
          hideAddButton
          hideHeading
          filters={[`beneficiaryId:${id}`]}
        />
      </Loading>
    </Page>
  )
}
