import axios from '../config/axiosConfig'
import { getRoles } from './authenticationService'

import { createSortUrl, createFilters } from './urlBuilder'

export const listBeneficiaries = async (
  search,
  page,
  limit,
  sort = {},
  filters = {},
) => {
  let url = `?${search}&page=${page}&size=${limit}${createSortUrl(
    sort,
  )}${createFilters(filters)}`
  if (getRoles().indexOf('ROLE_ADMIN') > -1) {
    url = `/v1/admin/beneficiaries${url}`
  } else {
    url = `/v1/beneficiaries${url}`
  }

  const response = await axios.get(url)

  response.data.content = response.data.content.map(content => {
    return content
  })
  return response.data
}

export const getBeneficiaryById = async id => {
  let url =
    getRoles().indexOf('ROLE_ADMIN') > -1
      ? `/v1/admin/beneficiaries/${id}`
      : `/v1/beneficiaries/${id}`
  const response = await axios.get(url)
  return response.data
}

export const getBalance = async id => {
  let url =
    getRoles().indexOf('ROLE_ADMIN') > -1
      ? `/v1/admin/beneficiaries/${id}/balances`
      : `/v1/beneficiaries/${id}/balances`
  const response = await axios.get(url)
  return response.data
}

export const getStatements = async (id, page, limit, from, to) => {
  let url =
    getRoles().indexOf('ROLE_ADMIN') > -1
      ? `/v1/admin/beneficiaries/${id}/statements?page=${page}&limit=${limit}&from=${from.toISOString()}&to=${to.toISOString()}`
      : `/v1/beneficiaries/${id}/statements?page=${page}&limit=${limit}&from=${from.toISOString()}&to=${to.toISOString()}`
  const response = await axios.get(url)
  return response.data
}

export const addBeneficiary = async beneficiary => {
  let url =
    getRoles().indexOf('ROLE_ADMIN') > -1
      ? `/v1/admin/beneficiaries`
      : `/v1/beneficiaries`
  const response = await axios.post(url, beneficiary)
  return response.data
}

export const updateBeneficiary = async (id, beneficiary) => {
  let url =
    getRoles().indexOf('ROLE_ADMIN') > -1
      ? `/v1/admin/beneficiaries/${id}`
      : `/v1/beneficiaries/${id}`
  if (beneficiary.id) {
    delete beneficiary.id
  }
  const response = await axios.put(url, beneficiary)
  return response.data
}

export const deleteBeneficiary = async id => {
  let url =
    getRoles().indexOf('ROLE_ADMIN') > -1
      ? `/v1/admin/beneficiaries/${id}`
      : `/v1/beneficiaries/${id}`
  const response = await axios.delete(url)
  return response.data
}

export const getBeneficiaryDocuments = async id => {
  const response = await axios.get(`/v1/beneficiaries/${id}/kyc`)
  return response.data
}

const convertObjectToFormData = obj => {
  const formData = new FormData()

  for (const [key, value] of Object.entries(obj)) {
    formData.append(key, value)
  }

  return formData
}

export const addBeneficiaryDocument = async documentData => {
  const formData = convertObjectToFormData(documentData)
  const response = await axios.post(
    `/v1/beneficiaries/${documentData.id}/files`,
    formData,
    {
      'Content-Type': 'multipart/form-data',
    },
  )

  return response.data
}

export const addMultipleBeneficiaryDocuments = async (files = []) => {
  const response = await Promise.all(
    files.map(file => addBeneficiaryDocument(file).then(resp => resp)),
  )

  return response
}

export const deleteAllBeneficiaryDocuments = async id => {
  const response = await axios.delete(`/v1/beneficiaries/${id}/files`)
  return response.data
}

export const updateBeneficiaryDocuments = async (id, files = []) => {
  await deleteAllBeneficiaryDocuments(id)

  const response = await addMultipleBeneficiaryDocuments(files)

  return response.data
}

export const startKyc = async id => {
  const response = await axios.post(`/v1/beneficiaries/${id}/kyx`)

  return response.data
}

export const approveKyx = async beneficiaryId => {
  const response = await axios.post(
    `/v1/beneficiaries/${beneficiaryId}/kyx-approve`,
  )

  return response.data
}
