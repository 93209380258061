import { useContext, useEffect, useState, useCallback } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import clsx from 'clsx'

// Services
import {
  getBeneficiaryById,
  updateBeneficiaryDocuments,
  approveKyx,
} from '../../services/beneficiaryService'
import { errorAlert, successAlert } from '../../services/alertService'

import { getRoles } from '../../services/authenticationService'

// Utils
import { getCustomShadows } from '../../theme/shadows'

// Material
import { Paper, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

// Components
import StatusPill from '../../components/ui/StatusPill'
import Button from '../../components/Button'
import Page from '../../components/Page'
import PageHeader from '../../components/PageHeader'
import Loading from '../../components/Loading'
import UploadBeneficiaryDocumentModal from '../../components/modals/UploadBeneficiaryDocumentModal'
import { ModalContext } from '../../components/providers/ModalProvider'
import { Icon } from '@iconify/react'

// Constants
import { SECTION_BASE_PATHS } from '../../utils/general'
import {
  KYC_STATUS_MAP,
  BENEFICIARY_DOCUMENT_TYPE_MAP,
  BENEFICIARY_DOCUMENT_TYPES,
  BENEFICIARY_USER_TYPES,
} from '../../utils/beneficiaries'

// Constants
const BENEFICIARIES_BASE_PATH = SECTION_BASE_PATHS.beneficiaries

// Local Utils
function getIntroMessage({ status }) {
  const hasMissingOrRejectedDocuments = [
    KYC_STATUS_MAP.PENDING.key,
    KYC_STATUS_MAP.FAILED.key,
  ].includes(status)

  if (hasMissingOrRejectedDocuments) {
    return 'Some documents that are needed to verify the beneficiary appear to be missing or have been rejected. Please upload all required documents for verification.'
  }

  const allDocumentsAreUnderReview =
    KYC_STATUS_MAP.KYC_IN_PROGRESS.key === status

  if (allDocumentsAreUnderReview) {
    return 'All documents are currently being reviewed. Please check back again for any updates on the verification status.'
  }

  const allDocumentsApproved = KYC_STATUS_MAP.CREATED.key === status

  if (allDocumentsApproved) {
    return 'All verification documents have been approved and the beneficiary has been fully verified.'
  }

  return ''
}

function __constructDefaultDocumentData({
  defaultDocumentTypes,
  beneficiaryId,
  beneficiaryType,
  documentOwner,
}) {
  return defaultDocumentTypes.map(documentType => ({
    id: beneficiaryId,
    documentOwner,
    documentOwnerType: beneficiaryType,
    file: null,
    name: null,
    type: documentType,
    classifier: 'FRONT',
    category: 'ID'
  }))
}

function useShowModalAction({
  beneficiaryData,
  filesList,
  saveAction,
}) {
  const { showModal } = useContext(ModalContext)

  return () => {
    showModal({
      data: {
        initialData: {
          beneficiaryId: beneficiaryData.id,
          files: filesList,
          beneficiaryType: beneficiaryData.type,
        },
        saveAction,
      },
      modalComponent: UploadBeneficiaryDocumentModal,
    })
  }
}

// Styled Components
const FormWrapper = styled('form')(({ theme }) => ({
  maxWidth: '480px',
  '.form_field': {
    marginBottom: theme.spacing(2),
  },
  '.form_field:last-child': {
    marginBottom: 0,
  },
}))
const FormFieldWrapper = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.light,
    borderRadius: 8,
    padding: theme.spacing(1, 1.5),
    boxShadow: getCustomShadows({ componentName: 'formField' }),
    // Specific for the Autocomplete/Select component
    '& .MuiAutocomplete-root': {
      flexGrow: 1,
      maxWidth: 300,
    },
    '& .MuiAutocomplete-root[aria-expanded="true"] .MuiOutlinedInput-root':
      {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        border: 'none',
      },
    '.MuiAutocomplete-root[aria-expanded="true"] .MuiOutlinedInput-notchedOutline':
      {
        border: `1px solid ${theme.palette.formFields.borderColor}`,
      },
  }
})
const Label = styled(props => <label {...props} />)(({ theme }) => {
  return {
    ...theme.typography.body1,
    color: theme.palette.blue.primary,
    marginRight: theme.spacing(1),
  }
})
const StyledIntroMessage = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))
const MainContentWrapper = styled('div')(({ theme }) => ({
  maxWidth: '640px',
}))
const IntroSectionWrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(5),
  '.document_status': {
    marginBottom: theme.spacing(3),
    width: '200px',
  },
}))
const ButtonGroup = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '.button:not(:last-of-type)': {
    marginRight: theme.spacing(2),
  },
}))

function IntroSectionHeading({
  status,
  showModalAction,
  approveAction,
}) {

  const showUploadButton = [
    KYC_STATUS_MAP.PENDING.key,
    KYC_STATUS_MAP.MANUAL_REVIEW.key,
    KYC_STATUS_MAP.KYC_IN_PROGRESS.key,
    KYC_STATUS_MAP.FAILED.key,
  ].includes(status.key)

  const isNotProduction =
    process.env.REACT_APP_IS_PRODUCTION !== 'MAIN'

  const [isBeingReviewed, setBeingReviewed] = useState(
    status.key === KYC_STATUS_MAP.KYC_IN_PROGRESS.key || 
    status.key === KYC_STATUS_MAP.MANUAL_REVIEW.key,
  )

  const [isApprovingState, setIsApprovingState] = useState(false)

  const approveActionAndHideButton = () => {
    setIsApprovingState(true)
    approveAction()
      .then(() => {
        setBeingReviewed(true)
        setIsApprovingState(false)
      })
      .catch(() => {
        setIsApprovingState(false)
      })
  }

  return (
    <IntroSectionWrapper>
      <StatusPill
        className={clsx('document_status', status.className)}>
        <Icon icon={status.iconifyIcon} className='icon' />
        {status.label}
      </StatusPill>

      <StyledIntroMessage component='p' variant='p'>
        {getIntroMessage({ status: status.key })}
      </StyledIntroMessage>

      <ButtonGroup className='button_group'>
        {showUploadButton ? (
          <Button
            onClick={showModalAction}
            className='document_action'>
            Upload Documents
          </Button>
        ) : null}

        {isNotProduction && isBeingReviewed ? (
          <Button
            onClick={approveActionAndHideButton}
            className='document_action'>
            {isApprovingState ? 'Approving...' : 'Approve KYC'}
          </Button>
        ) : null}
      </ButtonGroup>
    </IntroSectionWrapper>
  )
}

const BusinessBeneficiaryDocumentsFormWrapper = styled(FormWrapper)(
  ({ theme }) => ({
    '.documents_section:not(:last-of-type)': {
      marginBottom: theme.spacing(6),
    },
  }),
)

const BusinessBeneficiaryDocumentSectionHeading = styled(Typography)(
  ({ theme }) => ({
    marginBottom: theme.spacing(3),
  }),
)

function BusinessBeneficiaryDocumentView({
  saveAction,
  approveAction,
  beneficiaryData,
}) {

  const businessDocumentData = __constructDefaultDocumentData({
    defaultDocumentTypes:
      BENEFICIARY_DOCUMENT_TYPES[BENEFICIARY_USER_TYPES.BUSINESS.key],
    beneficiaryId: beneficiaryData.id,
    beneficiaryType: BENEFICIARY_USER_TYPES.BUSINESS.key,
    documentOwner: beneficiaryData.business.name,
  })

  const showModalAction = useShowModalAction({
    beneficiaryData,
    saveAction,
    filesList: [...businessDocumentData]
  })

  const status = KYC_STATUS_MAP[beneficiaryData.status]

  return (
    <BusinessBeneficiaryDocumentsFormWrapper>
      <IntroSectionHeading
        status={status}
        showModalAction={showModalAction}
        approveAction={approveAction}
      />

      <section className='business_beneficiary_documents_section documents_section'>
        <BusinessBeneficiaryDocumentSectionHeading
          component='p'
          variant='h5'>
          Business Documents
        </BusinessBeneficiaryDocumentSectionHeading>

        {businessDocumentData.map((documentData, index) => {
          const document =
            BENEFICIARY_DOCUMENT_TYPE_MAP[documentData.type]

          return (
            <FormFieldWrapper
              key={`${documentData.documentType}-${index}`}
              className='form_field'>
              <Label>{document.label}</Label>
            </FormFieldWrapper>
          )
        })}
      </section>
    </BusinessBeneficiaryDocumentsFormWrapper>
  )
}

function MainDocumentView({
  beneficiaryData,
  saveAction,
  approveAction,
}) {
  return (
    <MainContentWrapper className='container'>
        <BusinessBeneficiaryDocumentView
          beneficiaryData={beneficiaryData}
          saveAction={saveAction}
          approveAction={approveAction}
        />
    </MainContentWrapper>
  )
}

export default function BeneficiaryDocumentsPage() {
  const navigate = useNavigate()
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [beneficiaryData, setBeneficiaryData] = useState({
    files: [],
    status: KYC_STATUS_MAP.PENDING.key,
  })
  const goToListPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/list`)
  const goToViewPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/view`)
  const goToTransfersPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/transfers`)
  const goToPaymentsPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/payments`)
  const goToDocumentsPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/documents`)
  const goToBalance = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/balance`)
  const goToAmlCheck = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/amlcheck`)
  const goToKyx = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/kyx`)      
    
  const fetchData = useCallback(() => {
    setLoading(true)
    getBeneficiaryById(id)
      .then(beneficiary => {
        setBeneficiaryData(beneficiary)
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        errorAlert(
          'Error has occurred while fetching integrator info',
        )
        setLoading(false)
      })
  }, [setLoading, id])

  const onSaveSuccess = () => {
    fetchData()
    successAlert('Documents uploaded with success')
  }
  
  const onSaveFailure = error => {
    errorAlert(error.message)
  }

  const saveData = async ({ files }) => {
    updateBeneficiaryDocuments(id, files)
      .then(_ => {
        onSaveSuccess()
      })
      .catch(error => {
        onSaveFailure(error)
      })
  }

  const approveKycAction = async () => {
    const isProduction = process.env.REACT_APP_IS_PRODUCTION === 'MAIN'
    if(isProduction) {
      return;
    }
    
    return approveKyx(beneficiaryData.id)
      .then(resp => {
        successAlert('Beneficiary KYC has been approved')
        fetchData()
        return resp
      })
      .catch(err => {
        errorAlert(err.message)
        return err
      })
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const tabsData = {
    tabs: [
      { label: 'Basic Info', value: 'view', onClick: goToViewPage },
      {
        label: 'Transfers',
        value: 'transfers',
        onClick: goToTransfersPage,
      },
      {
        label: 'Payments',
        value: 'payments',
        onClick: goToPaymentsPage,
      },
      {
        label: 'Documents',
        value: 'documents',
        onClick: goToDocumentsPage,
      },
      {
        label: 'Balance',
        value: 'balance',
        onClick: goToBalance,
      },
      {
        label: 'KYX',
        value: 'kyx',
        onClick: goToKyx,
      }      
    ],
    selectedTab: 'documents',
  }

  const isAdmin = getRoles().indexOf('ROLE_ADMIN') > -1

  if(isAdmin) {
    tabsData.tabs.push({
      label: 'AML Check',
      value: 'amlcheck',
      onClick: goToAmlCheck,
    })
  }

  return (
    <Page className='main_content' title={'Beneficiary | Documents'}>
      <Loading loading={loading}>
        <PageHeader
          style={{ marginBottom: 32 }}
          backButtonAction={goToListPage}
          heading={`Beneficiary - Documents`}
          tabsData={tabsData}
        />
        { beneficiaryData.type === BENEFICIARY_USER_TYPES.BUSINESS.key ?
          (
            <MainDocumentView
              beneficiaryData={beneficiaryData}
              saveAction={saveData}
              approveAction={approveKycAction}
            />
          ) : (
            <Paper sx={{ backgroundColor: 'transparent' }}>
              <Typography
                gutterBottom
                align='center'
                variant='subtitle1'
                sx={{ fontSize: 18 }}
                mx={{ align: 'center' }}>
                Individual beneficiaries does not provide documents
              </Typography>
            </Paper>
          )}
      </Loading>
    </Page>
  )
}
