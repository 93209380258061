import axios from '../config/axiosConfig'
import axios2 from 'axios'
import { isJwtExpired } from 'jwt-check-expiration'

const queryString = require('query-string')

const PAGES = [
  {
    resource: 'currencies',
    action: 'list',
    roles: ['ROLE_ADMIN'],
  },
  {
    resource: 'currencies',
    action: 'add',
    roles: ['ROLE_ADMIN'],
  },
  {
    resource: 'currencies',
    action: 'edit',
    roles: ['ROLE_ADMIN'],
  },
  {
    resource: 'countries',
    action: 'list',
    roles: ['ROLE_ADMIN'],
  },
  {
    resource: 'countries',
    action: 'add',
    roles: ['ROLE_ADMIN'],
  },
  {
    resource: 'countries',
    action: 'edit',
    roles: ['ROLE_ADMIN'],
  },
  {
    resource: 'admins',
    action: 'list',
    roles: ['ROLE_ADMIN'],
  },
  {
    resource: 'admins',
    action: 'add',
    roles: ['ROLE_ADMIN'],
  },
  {
    resource: 'admins',
    action: 'edit',
    roles: ['ROLE_ADMIN'],
  },
  {
    resource: 'profiles',
    action: 'edit',
    roles: ['ROLE_ADMIN', 'ROLE_INTEGRATOR'],
  },
  {
    resource: 'wallets',
    action: 'list',
    roles: ['ROLE_ADMIN'],
  },
  {
    resource: 'balance',
    action: 'list',
    roles: ['ROLE_ADMIN', 'ROLE_INTEGRATOR'],
  },
  {
    resource: 'integrators',
    action: 'add',
    roles: ['ROLE_ADMIN'],
  },
  {
    resource: 'integrators',
    action: 'edit',
    roles: ['ROLE_ADMIN'],
  },
  {
    resource: 'integrators',
    action: 'list',
    roles: ['ROLE_ADMIN'],
  },
  {
    resource: 'beneficiaries',
    action: 'add',
    roles: ['ROLE_ADMIN', 'ROLE_INTEGRATOR'],
  },
  {
    resource: 'beneficiaries',
    action: 'edit',
    roles: ['ROLE_ADMIN', 'ROLE_INTEGRATOR'],
  },
  {
    resource: 'beneficiaries',
    action: 'list',
    roles: ['ROLE_ADMIN', 'ROLE_INTEGRATOR'],
  },
  {
    resource: 'transfers',
    action: 'add',
    roles: ['ROLE_ADMIN', 'ROLE_INTEGRATOR'],
  },
  {
    resource: 'transfers',
    action: 'edit',
    roles: ['ROLE_ADMIN', 'ROLE_INTEGRATOR'],
  },
  {
    resource: 'transfers',
    action: 'list',
    roles: ['ROLE_ADMIN', 'ROLE_INTEGRATOR'],
  },
  {
    resource: 'team',
    action: 'list',
    roles: ['ROLE_INTEGRATOR'],
  },
  {
    resource: 'team',
    action: 'add',
    roles: ['ROLE_INTEGRATOR'],
  },
  {
    resource: 'payments',
    action: 'add',
    roles: ['ROLE_ADMIN', 'ROLE_INTEGRATOR'],
  },
  {
    resource: 'payments',
    action: 'edit',
    roles: ['ROLE_ADMIN', 'ROLE_INTEGRATOR'],
  },
  {
    resource: 'payments',
    action: 'list',
    roles: ['ROLE_ADMIN', 'ROLE_INTEGRATOR'],
  }, 
  {
    resource: 'statements',
    action: 'list',
    roles: ['ROLE_ADMIN', 'ROLE_INTEGRATOR'],
  },
  {
    resource: 'statements',
    action: 'list',
    roles: ['ROLE_ADMIN', 'ROLE_INTEGRATOR'],
  },
  {
    resource: 'ledger_templates',
    action: 'edit',
    roles: ['ROLE_ADMIN'],
  },
  {
    resource: 'payment-contacts',
    action: 'add',
    roles: ['ROLE_ADMIN', 'ROLE_INTEGRATOR'],
  },
  {
    resource: 'payment-contacts',
    action: 'edit',
    roles: ['ROLE_ADMIN', 'ROLE_INTEGRATOR'],
  },
  {
    resource: 'payment-contacts',
    action: 'list',
    roles: ['ROLE_ADMIN', 'ROLE_INTEGRATOR'],
  },
  {
    resource: 'account-settings',
    action: 'edit',
    roles: ['ROLE_ADMIN', 'ROLE_INTEGRATOR'],
  },
  {
    resource: 'accounts-tree',
    action: 'edit',
    roles: ['ROLE_ADMIN'],
  },
  {
    resource: 'accounts-t-view',
    action: 'edit',
    roles: ['ROLE_ADMIN'],
  },
  {
    resource: 'transaction-accounts-view',
    action: 'edit',
    roles: ['ROLE_ADMIN'],
  },
  {
    resource: 'accounts-transactions-view',
    action: 'edit',
    roles: ['ROLE_ADMIN'],
  },
  {
    resource: 'accounts-transactions-view',
    action: 'list',
    roles: ['ROLE_ADMIN'],
  },
  {
    resource: 'accounts-transactions-list',
    action: 'edit',
    roles: ['ROLE_ADMIN'],
  },
  {
    resource: 'reports',
    action: 'add',
    roles: ['ROLE_ADMIN'],
  },
  {
    resource: 'pendingwire',
    action: 'list',
    roles: ['ROLE_ADMIN'],
  },
  {
    resource: 'amllock',
    action: 'list',
    roles: ['ROLE_ADMIN'],
  },
  {
    resource: 'amllock',
    action: 'add',
    roles: ['ROLE_ADMIN'],
  },
  {
    resource: 'amllock',
    action: 'edit',
    roles: ['ROLE_ADMIN'],
  },
  {
    resource: 'batchoperation',
    action: 'edit',
    roles: ['ROLE_ADMIN']
  },
  {
    resource: 'batchoperation',
    action: 'list',
    roles: ['ROLE_ADMIN']
  },
  {
    resource: 'pending-swift-transfers',
    action: 'list',
    roles: ['ROLE_ADMIN']
  },
  {
    resource: 'pending-swift-transfers',
    action: 'edit',
    roles: ['ROLE_ADMIN']
  },
  {
    resource: 'exchanged-funds-available',
    action: 'list',
    roles: ['ROLE_ADMIN']
  },
  {
    resource: 'exchanged-funds-available',
    action: 'edit',
    roles: ['ROLE_ADMIN']
  },
  {
    resource: 'deposit-refunding',
    action: 'list',
    roles: ['ROLE_ADMIN']
  },
  {
    resource: 'deposit-refunding',
    action: 'edit',
    roles: ['ROLE_ADMIN']
  },
]

export const redirectToLogin = () => {
  window.location = `${
    process.env.REACT_APP_KEYCLOAK
  }/auth/realms/caliza/protocol/openid-connect/auth?client_id=${
    process.env.REACT_APP_CLIENT_ID
  }&response_type=code&state=${uuidv4()}
  &redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`
}

export const getToken = async code => {
  var data = queryString.stringify({
    grant_type: 'authorization_code',
    client_id: process.env.REACT_APP_CLIENT_ID,
    code,
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
  })

  return axios2({
    url: `${process.env.REACT_APP_KEYCLOAK}/auth/realms/caliza/protocol/openid-connect/token`,
    method: 'post',
    data,
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
    },
  })
    .then(result => result.data)
    .then(result => {
      storeCredentials({
        token: result.access_token,
      })
      return result
    })
    .catch(error => redirectToLogin())
}

export const getMe = async () => {
  try {
    const response = await axios.get(`/v1/users/me`)
    return response.data
  } catch (e) {
    redirectToLogin()
  }
}

export const doLogin = async ({ username, password }) => {
  const response = await axios.post(`/v1/auth`, {
    username,
    password,
  })
  return response.data
}

export const logout = async () => {
  try {
    const response = await axios.delete(`/v1/users/logout`)
    localStorage.removeItem('auth')
    return response.data
  } catch (e) {
    if (localStorage.getItem('auth')) {
      localStorage.removeItem('auth')
    }
  }
}

export const storeCredentials = tokenResponse => {
  localStorage.setItem('auth', JSON.stringify(tokenResponse))
}

export const getCurrentUser = () => {
  const user = localStorage.getItem('auth')
  const token = JSON.parse(user)

  if (!token || !token.token) {
    return null
  }

  if (isJwtExpired(token.token)) {
    return null
  }

  return token
}

export const getAvailableRoutesForCurrentUser = () => {
  const current = getCurrentUser()
  if (!current) {
    return {}
  }

  current.authorities = parseJwtAndGetRoles(current.token)
  const filteredPages = PAGES.filter(page => {
    const allowedRoles = page.roles
    if (!allowedRoles) {
      return true
    }
    const isAllowed = allowedRoles.filter(f => {
      return current.authorities.includes(f)
    })

    return isAllowed.length > 0
  })

  const response = {}
  filteredPages.forEach(page => {
    response[page.resource + '/' + page.action] = page
  })

  return response
}

export const checkRoute = (routes, item) => {
  if (!item.resource || !item.action) {
    return true
  }

  const route = routes[item.resource + '/' + item.action]
  if (!route) {
    return false
  }
  return true
}

export const getRoles = () => {
  const current = getCurrentUser()
  if (!current) {
    return []
  }

  return parseJwtAndGetRoles(current.token)
}

export const getIntegratorId = () => {
  const current = getCurrentUser()
  if (!current) {
    return {}
  }
  const instrospectedToken = parseJwt(current.token)
  return instrospectedToken['integrator-id']
}

function parseJwtAndGetRoles(token) {
  try {
    const instrospectedToken = parseJwt(token)
    if (instrospectedToken) {
      return instrospectedToken.realm_access.roles
    }

    return []
  } catch (e) {
    return []
  }
}

function parseJwt(token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  )
}
