import { useState } from 'react'
import PropTypes from 'prop-types'

// Material
import { Stack, Container, Typography } from '@mui/material'

// Components
import Table from '../../components/Table'
import Loading from '../../components/Loading'
import PageHeading from '../../components/ui/PageHeading'

// Constants
const ASC_FILTER = 'asc'
const DESC_FILTER = 'desc'

// Main
GenericList.propTypes = {
  filters: PropTypes.shape({
    filterSets: PropTypes.arrayOf(PropTypes.object),
  }),
}

export default function GenericList({
  label,
  subTitle,
  loading,
  filters,
  hideOptions = false,
  hideAddButton = false,
  hideSearchFilter = false,
  goToView,
  goToAddPage,
  goToEditPage,
  onDelete,
  onRowsPerPageChange,
  onPageChange,
  onSearch,
  onRequestSort,
  headers,
  page,
  rowsPerPage,
  dataWrapper,
  onTableRowClick,
  useAccordionTableRow,
  renderExpandableTableRow,
  addButtonLabel,
}) {
  const initialFilter = {}
  headers.forEach(h => {
    initialFilter[h.id] = {
      id: h.id,
      sortAsc: true,
    }
  })
  const [sort, setSort] = useState(initialFilter)

  return (
    <>
      <Loading loading={loading}>
        <Container style={{ padding: 0, margin: 0 }}>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            mb={5}>
            <PageHeading gutterBottom>{label}</PageHeading>

            <Typography
              variant='h6'
              gutterBottom
              style={{ color: '#3B7DC1' }}>
              {subTitle}
            </Typography>
          </Stack>
          <Table
            hideSearchFilter={hideSearchFilter}
            hideAddButton={hideAddButton}
            useAccordionTableRow={useAccordionTableRow}
            renderExpandableTableRow={renderExpandableTableRow}
            label={label}
            headers={headers}
            data={dataWrapper}
            filters={filters}
            hideOptions={hideOptions}
            currentPage={page}
            rowsPerPage={rowsPerPage}
            onTableRowClick={onTableRowClick}
            goToAddPage={goToAddPage}
            addButtonLabel={addButtonLabel}
            onRequestSort={filterName => {
              const selectedSort = sort[filterName]
              selectedSort.sortAsc = !selectedSort.sortAsc
              setSort(sort)

              const sortBy = {
                filter: filterName,
                type: selectedSort.sortAsc ? ASC_FILTER : DESC_FILTER,
              }

              onRequestSort(sortBy)
            }}
            onSearch={search => onSearch(search)}
            onPageChange={page => onPageChange(page)}
            onRowsPerPageChange={perPage => onRowsPerPageChange(perPage)}
            onEdit={goToEditPage}
            onView={goToView && (id => goToView(id))}
            onRemove={
              onDelete &&
              (id => {
                onDelete(id)
              })
            }
          />
        </Container>
      </Loading>
    </>
  )
}
