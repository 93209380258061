import {
  getAvailableRoutesForCurrentUser,
  checkRoute,
} from './../../services/authenticationService'

// ----------------------------------------------------------------------
const isProduction = process.env.REACT_APP_IS_PRODUCTION === 'MAIN'
const availableRoutes = getAvailableRoutesForCurrentUser()

let sidebarConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
  },
  {
    title: 'Beneficiaries',
    path: '/dashboard/beneficiaries/list',
    resource: 'beneficiaries',
    action: 'list',
  },
  {
    title: 'Payments',
    path: '/dashboard/payments/list',
    resource: 'payments',
    action: 'list',
  },
  {
    title: 'Integrators',
    path: '/dashboard/integrators/list',
    resource: 'integrators',
    action: 'list',
  },
  {
    title: 'Transfers',
    path: '/dashboard/transfers/list',
    resource: 'transfers',
    action: 'list',
  },
  {
    title: 'Team',
    path: '/dashboard/team/list',
    resource: 'team',
    action: 'list',
  },
  {
    title: 'Admin Management',
    path: '/dashboard/admins/list',
    resource: 'admins',
    action: 'list',
  },
  {
    title: 'Currency Management',
    path: '/dashboard/currencies/list',
    resource: 'currencies',
    action: 'list',
  },
  {
    title: 'Country Management',
    path: '/dashboard/countries/list',
    resource: 'countries',
    action: 'list',
  },
  {
    title: 'Payment Contacts Management',
    path: '/dashboard/payment-contacts/list',
    resource: 'payment-contacts',
    action: 'list',
  },

  {
    title: 'Reports',
    path: '/dashboard/reports/save',
    resource: 'reports',
    action: 'add',
  },
  {
    title: 'Failed Wire IN',
    path: '/dashboard/pendingwire/list',
    resource: 'pendingwire',
    action: 'list',
  },
  {
    title: 'AML Lock',
    path: '/dashboard/amllock/list',
    resource: 'amllock',
    action: 'list',
  },
  {
    title: 'Account Tree',
    path: '/dashboard/accounts/tree',
    resource: 'amllock',
    action: 'list',
  },
  {
    title: 'Account T View',
    path: '/dashboard/accounts/t-view',
    resource: 'amllock',
    action: 'list',
  },
  {
    title: 'Accounts View',
    path: '/dashboard/accounts/transactions/view',
    resource: 'accounts-transactions-view',
    action: 'list',
  },
  {
    title: 'Start Internal Settlement OUT',
    path: '/dashboard/batch-operation/out/list',
    resource: 'batchoperation',
    action: 'list',
  },
  {
    title: 'Ledger Template Edit',
    path: '/dashboard/ledger_templates/edit',
    resource: 'ledger_templates',
    action: 'edit',
  },
  {
    title: 'Deposit Refunding',
    path: '/dashboard/deposit-refunding/list',
    resource: 'deposit-refunding',
    action: 'list',
  }
]

export default sidebarConfig.filter(item =>
  checkRoute(availableRoutes, item),
)
